import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { NavBar } from "./layout/Navbar/Navbar";
import { HomeScreen } from "./components/screens/HomeScreen/HomeScreen";
import { ContactUsScreen } from "./components/screens/ContactUsScreen/ContactUsScreen";
import { AboutUsScreen } from "./components/screens/AboutUsScreen/AboutUsScreen";
import Footer from "./layout/Footer/Footer";

const App = () => {
  return (
    <Router>
      <NavBar />
      <main className="min-h-screen ">
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/contact" element={<ContactUsScreen />} />
          <Route path="/about" element={<AboutUsScreen />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
};

export default App;
