import { HeroSection } from "./HeroSection/HeroSection";
import { ProductSection } from "./ProductSection/ProductSection";

export const HomeScreen = () => {
  return (
    <div className="mx-auto">
      <HeroSection />
      <ProductSection />
    </div>
  );
};
