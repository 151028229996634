import AboutUsGraphic from "../../../assets/images/AboutUsGraphic.png";
export const AboutUsScreen = () => {
  return (
    <section className="px-[5%] pb-24 sm:px-[20%]">
      <div className="h-full px-[5%] pb-20 pt-32 sm:px-[10%] sm:pt-36">
        <h2 className="text-3xl font-extrabold text-coolorsPurple2 sm:text-4xl">
          Changing the world one bite at a time
        </h2>
        <div className="my-6 h-2 w-10 bg-primary sm:my-8"></div>
        <div className="text-lg font-light text-coolorsPurple2 sm:text-2xl">
          <p>
            Our mission is to make the world a smaller place by connecting more
            people with more new food.
          </p>
          <br />
          <p>
            Muffin is a social media platform where foodies, casual eaters, and
            restaurant owners can all come together to share their love of food.
            Through our focus on the food, we hope that more people will be
            enticed to try new dishes from different cultures.
          </p>
          <br />
          <p>
            We apologize if you are visiting from outside the region. Currently,
            we only operate in Southern California. Sign up for our email list
            to get notified once we're in your area!
          </p>
        </div>
      </div>
      <img
        src={AboutUsGraphic}
        alt="AboutUsGraphic
      "
      />
    </section>
  );
};
