import { Link, useLocation } from "react-router-dom";
import MuffinLogo from "../../assets/images/MuffinLogo.svg";
import { useState, useEffect } from "react";

export const NavBar: React.FC = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const toggleMenu = () => {
    setShowMenu((prev) => !prev);
  };

  const location = useLocation();

  useEffect(() => {
    // Scroll to the top when navigating to a new page
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <nav className="fixed left-0 right-0 top-0 z-50 border-b-[0.5px] border-primary-extraLight border-primary-extraLight bg-black/30 shadow-sm backdrop-blur transition-all duration-300 ease-in-out">
      <div className="mx-auto max-w-7xl px-4 sm:px-8 ">
        <div className="flex h-16 items-center justify-between">
          <div className="flex items-center ">
            <div className="flex-shrink-0 hover:opacity-70">
              <Link
                to="/"
                onClick={() => setShowMenu(false)}
                className={
                  "flex  items-center text-2xl font-bold text-white hover:text-primary-extraLight"
                }
              >
                <img
                  src={MuffinLogo}
                  alt="Muffin Logo"
                  className=" mx-2 h-8 w-8 "
                />
                Muffin
              </Link>
            </div>
          </div>
          <div className="flex items-center">
            <div className="ml-auto hidden sm:block">
              {showMenu === false ? (
                <div className="flex items-baseline space-x-4 sm:space-x-12">
                  <Link
                    to="/about"
                    className={`${
                      location.pathname === "/about"
                        ? "text-primary-light"
                        : "text-white"
                    } rounded-md py-2 text-xl font-medium hover:text-primary`}
                  >
                    About Us
                  </Link>
                  <Link
                    to="/contact"
                    className={`${
                      location.pathname === "/contact"
                        ? "text-primary-light"
                        : "text-white"
                    } rounded-md py-2 text-xl font-medium hover:text-primary`}
                  >
                    Contact Us
                  </Link>
                  {/* Add more links here */}
                </div>
              ) : null}
            </div>
            <div className="sm:hidden">
              <button
                onClick={toggleMenu}
                className="text-white focus:outline-none"
                aria-label="Toggle Menu"
              >
                <svg
                  className="h-6 w-6 fill-current"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {showMenu ? (
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4 6h16v2H4V6zm0 5h16v2H4v-2zm0 5h16v2H4v-2z"
                    />
                  ) : (
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4 6h16v2H4V6zm0 5h16v2H4v-2zm0 5h16v2H4v-2z"
                    />
                  )}
                </svg>
              </button>
            </div>
          </div>
        </div>
        {showMenu && (
          <div className=" z-50 my-4 sm:hidden">
            <div className="flex flex-col items-center">
              <Link
                to="/about"
                onClick={toggleMenu}
                className={`${
                  location.pathname === "/about"
                    ? "text-primary-light"
                    : "text-white"
                } rounded-md px-3 py-2 text-lg font-medium hover:text-primary`}
              >
                About Us
              </Link>
              <Link
                to="/contact"
                onClick={toggleMenu}
                className={`${
                  location.pathname === "/contact"
                    ? "text-primary-light"
                    : "text-white"
                } rounded-md px-3 py-2 text-lg font-medium hover:text-primary`}
              >
                Contact Us
              </Link>
              {/* Add more links here */}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};
