import DemoVideoWebM from "../../assets/videos/DemoVideo.webm";
import DemoVideoMP4 from "../../assets/videos/DemoVideo.mp4";
import React, { useRef } from "react";

const VideoPlayer: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handleContainerClick = () => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.log("Autoplay failed:", error);
      });
    }
  };
  // useEffect(() => {
  //   if (videoRef.current) {
  //     setTimeout(() => {
  //       videoRef.current?.play().catch((error) => {
  //         console.log("Autoplay failed:", error);
  //       });
  //     }); // Delay for 1 second
  //   }
  // }, []);

  return (
    <div
      className="flex h-full w-full items-center justify-center"
      onClick={handleContainerClick}
    >
      <video
        ref={videoRef}
        className="w-full max-w-lg"
        autoPlay
        loop
        muted
        playsInline
        preload="auto"
      >
        <source src={DemoVideoMP4} type="video/mp4" />
        <source src={DemoVideoWebM} type="video/webm" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
