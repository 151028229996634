import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import Send from "../../../assets/icons/Send.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ContactUsForm = () => {
  const form = useRef<HTMLFormElement>(null);

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const currentForm = form.current;
    if (currentForm === null) return console.log("current form is null");

    const emailjsServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    if (emailjsServiceId === undefined || emailjsServiceId === null)
      throw new Error("Service Id is empty");

    const emailjsTemplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    if (emailjsTemplateId === undefined || emailjsTemplateId === null) {
      throw new Error("Template Id is empty");
    }

    const emailjsPublicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
    if (emailjsPublicKey === undefined || emailjsPublicKey === null) {
      throw new Error("Public Key is empty");
    }

    emailjs
      .sendForm(
        emailjsServiceId,
        emailjsTemplateId,
        currentForm,
        emailjsPublicKey
      )
      .then(
        (result) => {
          toast.success(
            "Thank you for contacting us! We will get back to you as soon as possible"
          );
        },
        (error) => {
          toast.error("An error occurred with your request");
        }
      );
    currentForm.reset();
  };
  return (
    <div className="flex w-full justify-center ">
      {/* Form fields */}
      <form ref={form} onSubmit={sendEmail} className="sm:w-1/2">
        <div className="rounded-lg bg-white shadow-md">
          <input
            type="name"
            name="user_name"
            placeholder="Name"
            className="focus:ring-primaryLight w-full border-b-2 bg-transparent px-3 py-2 focus:outline-none focus:ring-2"
            required
          />
          <input
            type="email"
            name="user_email"
            placeholder="Email"
            className="focus:ring-primaryLight w-full border-b-2 bg-transparent px-3 py-2 focus:outline-none focus:ring-2"
            required
          />
          <input
            type="text"
            name="subject"
            placeholder="Subject"
            className="focus:ring-primaryLight w-full border-b-2 bg-transparent px-3 py-2 focus:outline-none focus:ring-2"
            required
          />
          <textarea
            className="focus:ring-primaryLight h-32 w-full resize-none bg-transparent px-3 py-2 focus:outline-none focus:ring-2"
            name="message"
            placeholder="Message"
            required
          />
        </div>
        <div className="my-4 flex justify-center sm:mt-8">
          <button
            type="submit"
            className="hover:bg-primaryLight flex w-full items-center justify-between rounded-md bg-gradient-to-br from-primary via-primary-light to-primary-extraLight px-4 py-2 text-white shadow-sm focus:outline-none"
          >
            <p className="ml-8 flex-grow text-center">Send</p>
            <img src={Send} className="ml-2 h-6 w-6" alt="Send" />
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};
