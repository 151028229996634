import React from "react";
import { FaInstagram, FaFacebook, FaTwitter } from "react-icons/fa";
import MuffinLogo from "../../assets/images/MuffinLogo.svg";

const Footer: React.FC = () => {
  return (
    <footer className="bottom-0 left-0 w-screen bg-gray-200 px-8 pb-4 pt-8">
      <div className="mx-auto max-w-5xl">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between">
            <img
              src={MuffinLogo}
              alt="Muffin Logo"
              className=" mr-2 h-6 w-6 "
            />
            <div className="text-xl font-bold text-primary">Muffin</div>
          </div>
          <div className="flex space-x-4">
            <a
              href="https://www.instagram.com/muffinfoodapp/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              <FaInstagram size={24} />
            </a>
            <a
              href="https://twitter.com/MuffinFoodApp"
              target="_blank"
              rel="noopener noreferrer"
              className="mr-4 text-primary"
            >
              <FaTwitter size={24} />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100095060299826"
              target="_blank"
              rel="noopener noreferrer"
              className="mr-4 text-primary"
            >
              <FaFacebook size={24} />
            </a>
          </div>
        </div>
        <div className="mt-4 flex justify-between text-sm text-gray-600">
          <div className="text-primary">
            &copy; {new Date().getFullYear()} Muffin App, INC. All Rights
            Reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
