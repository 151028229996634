import "./BackgroundAnimationStyles.css";

export const GradientAnimation = () => {
  return (
    <>
      <div className="fade-animation">
        <div className="swell-animation">
          <div className="color-animation">
            <div className="rotate-animation">
              <div className="scale-animation">
                <div className="blur-animation ">
                  <div className="wave  absolute inset-1 z-10 mt-[5%] w-full opacity-[90%]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fade-animation2">
        <div className="swell-animation2">
          <div className="color-animation2">
            <div className="rotate-animation2">
              <div className="scale-animation2">
                <div className="blur-animation2 ">
                  <div className="wave  absolute inset-1 z-10 mt-[5%] w-full opacity-[90%]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
