import { ContactUsForm } from "../../forms/ContactUsForm/ContactUsForm";
import { Link } from "react-router-dom";
import { FaInstagram, FaFacebook, FaTwitter } from "react-icons/fa";
import "../../../components/animations/BackgroundAnimationStyles.css";
import { GradientAnimation } from "../../animations/GradientAnimation";

export const ContactUsScreen = () => {
  return (
    <section className="mx-auto pb-96 sm:pb-24">
      <div className="duration-10 z-0 h-[500px] w-full animate-gradient-10s overflow-hidden bg-gradient-to-tl from-primary-light via-primary to-primary-extraLight bg-size-200 bg-pos-0 px-4 pb-24 pt-32 text-center text-coolorsWhite sm:h-[600px] sm:w-full sm:pb-32 sm:pt-48">
        <GradientAnimation />
      </div>
      <div className="z-2 absolute top-0 w-full px-8 pt-28 sm:mt-5 ">
        <h2 className="pb-8 pt-4 text-center text-3xl font-extrabold text-white sm:py-8 sm:text-5xl">
          Contact Us
        </h2>
        <p className="mx-auto mb-8 max-w-3xl text-center text-white sm:mb-12 sm:text-xl">
          We would love to hear from you! Reach us on any of our social media or
          email us directly by filling out the form below.
        </p>
        <div className="ml-2 flex w-full justify-center  sm:w-auto">
          <div className="mb-4 grid grid-cols-2 justify-center gap-2 sm:flex sm:w-1/2 sm:space-x-5">
            {/* <div
              onClick={(e) => {
                window.open("mailto:nhkim@muffinapp.io?body={Name}:");
              }}
              className="mt-1.5 flex cursor-pointer items-center"
            >
              <div className="flex h-9 w-9 items-center rounded-md bg-white p-1 shadow-sm sm:p-1.5">
                <img src={Email} className="h-auto w-auto " alt="Email" />
              </div>
              <div className="ml-2">
                <p className="text-sm text-white">Email</p>
                <p className="text-[12px] text-white">nhkim@muffinapp.io</p>
              </div>
            </div> */}
            <Link
              to={"https://www.instagram.com/muffinfoodapp/"}
              target="_blank"
              className="flex items-center"
            >
              <div className="h-9 w-9 rounded-md bg-white p-1.5 shadow-sm">
                <FaInstagram color="#F04417" size={"auto"} />
              </div>
              <div className="ml-2">
                {/* <p className="text-sm text-white">Instagram</p> */}
                <p className="text-xs text-white sm:text-base">
                  @muffinfoodapp
                </p>
              </div>
            </Link>
            <Link
              to={"https://twitter.com/MuffinFoodApp"}
              target="_blank"
              className="flex items-center"
            >
              <div className="h-9 w-9 rounded-md bg-white p-1.5 shadow-sm">
                <FaTwitter color="#F04417" size={"auto"} />
              </div>
              <div className="ml-2">
                {/* <p className="text-sm text-white">Twitter</p> */}
                <p className="text-xs text-white sm:text-base">
                  @MuffinFoodApp
                </p>
              </div>
            </Link>
            <Link
              to={"https://www.facebook.com/profile.php?id=100095060299826"}
              target="_blank"
              className="flex items-center"
            >
              <div className="h-9 w-9 rounded-md bg-white p-1.5 shadow-sm">
                <FaFacebook color="#F04417" size={"auto"} />
              </div>
              <div className="ml-2">
                <p className="text-sm text-white sm:text-base">Facebook</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="mt-4">
          <ContactUsForm />
        </div>
      </div>
    </section>
  );
};
