import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";

import "./ToastStyles.css";

export const BetaEmailForm = () => {
  const form = useRef<HTMLFormElement>(null);

  const sendBetaEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const currentForm = form.current;
    if (currentForm === null) return console.log("current form is null");

    const emailjsBetaServiceId = process.env.REACT_APP_BETA_EMAILJS_SERVICE_ID;
    if (emailjsBetaServiceId === undefined || emailjsBetaServiceId === null)
      throw new Error("Service Id is empty");

    const emailjsBetaTemplateId =
      process.env.REACT_APP_BETA_EMAILJS_TEMPLATE_ID;
    if (emailjsBetaTemplateId === undefined || emailjsBetaTemplateId === null) {
      throw new Error("Template Id is empty");
    }

    const emailjsPublicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
    if (emailjsPublicKey === undefined || emailjsPublicKey === null) {
      throw new Error("Public Key is empty");
    }

    emailjs
      .sendForm(
        emailjsBetaServiceId,
        emailjsBetaTemplateId,
        currentForm,
        emailjsPublicKey
      )
      .then(
        (result) => {
          toast.success(
            "Thank you for registering! We will send you updates and information about the beta"
          );
          // onSuccess(); // Call the success callback
        },
        (error) => {
          toast.error("Failed to send email.");
        }
      );
    currentForm.reset();
  };

  return (
    <div>
      <form
        className="font-normal sm:ml-5 sm:flex sm:w-[75%] sm:flex-col "
        ref={form}
        onSubmit={sendBetaEmail}
      >
        <input
          type="fullName"
          name="beta_user_full_name"
          placeholder="Name"
          className="mb-4 w-[70%] rounded-md bg-coolorsWhite px-4 py-2 text-coolorsPurple2  shadow-sm focus:outline-none focus:ring-0 sm:w-full"
          required
        />
        <input
          type="email"
          name="beta_user_email"
          placeholder="Email"
          className="mb-4 w-[70%] rounded-md bg-coolorsWhite px-4 py-2 text-coolorsPurple2  shadow-sm  focus:outline-none focus:ring-0 sm:w-full"
          required
        />
        <button
          type="submit"
          className="mb-10 mt-4 cursor-pointer rounded-full bg-gradient-to-br from-blue-500 via-coolorsBlue to-blue-300 p-3 px-9 text-xl font-bold text-white shadow-md hover:opacity-95 sm:w-48 sm:self-center"
        >
          Join Waitlist
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};
