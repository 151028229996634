import { Link } from "react-scroll";
import BackgroundTextileMobile from "../../../../assets/images/BackgroundTextileMobile.png";

import { gradientAnimation } from "../../../animations/constants";

import { getTranslateYAnimation } from "../../../helpers/getTranslateYAnimation";
import VideoPlayer from "../../../VideoPlayer/VideoPlayer";
import IPhone14Pro from "../../../../assets/images/IPhone14ProMock.png";
import Plate from "../../../../assets/images/Plate.png";
import Comments from "../../../../assets/images/Comments.png";
import Bookmarks from "../../../../assets/images/Bookmarks.png";
import { useInView } from "react-intersection-observer";

export const ProductSection = () => {
  const [ref1, inView1] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });
  const [ref2, inView2] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });
  const [ref3, inView3] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });
  const [ref4, inView4] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });
  const translateYAnimation1 = getTranslateYAnimation(inView1);
  const translateYAnimation2 = getTranslateYAnimation(inView2);
  const translateYAnimation3 = getTranslateYAnimation(inView3);
  const translateYAnimation4 = getTranslateYAnimation(inView4);

  return (
    <section
      style={{
        backgroundImage: `url(${BackgroundTextileMobile})`,
      }}
      className="flex min-h-screen  min-w-full flex-col items-center bg-contain bg-center bg-repeat-y"
    >
      <div
        ref={ref1}
        className={`${translateYAnimation1}  mx-5 pt-24 text-center sm:hidden`}
      >
        <h2 className={` ${gradientAnimation} text-4xl`}>Discover</h2>
        <h2 className={` ${gradientAnimation} text-4xl`}>hidden gems</h2>
        <p className="mt-2 text-lg font-semibold text-coolorsPurple2">
          Swipe to see popular and trending food near you
        </p>
        <div>
          <div className="scale-75">
            {/* //* Below is a github Copilot suggestion for making the iphone image lay exactly on top of the video player or image underneath it. Do not touch this css code */}
            <div className="absolute left-1/2 top-1/2 z-[-1] h-full w-full -translate-x-1/2 -translate-y-1/2 transform overflow-hidden rounded-[20%] bg-transparent p-4">
              <div className="h-full w-full overflow-hidden">
                <VideoPlayer />
              </div>
            </div>
            <img src={IPhone14Pro} alt="IPhone14Pro" className="block w-full" />
          </div>
        </div>
      </div>
      <div
        ref={ref2}
        className={`${translateYAnimation2} text-center sm:mt-36`}
      >
        <h2
          className={`${gradientAnimation} text-3xl  sm:text-6xl`}
          style={{ lineHeight: "1.25" }}
        >
          Can't find something
        </h2>
        <h2
          className={`${gradientAnimation} mt-[-1%] text-3xl sm:text-6xl`}
          style={{ lineHeight: "1.25" }}
        >
          good to eat?
        </h2>
        <div className="p-5 text-center ">
          <p className="text-lg font-semibold text-coolorsPurple2 sm:mx-[10%] sm:text-2xl">
            Muffin quickly finds meals matching your diet and location,
            eliminating endless menu scrolling
          </p>
          <img
            className="my-5 sm:my-[-15%] sm:scale-50"
            src={Plate}
            alt="Plate"
          />
        </div>
      </div>
      <div ref={ref3} className={`${translateYAnimation3} mx-5 text-center`}>
        <h2
          style={{ lineHeight: "1.25" }}
          className={`${gradientAnimation} text-4xl sm:text-6xl`}
        >
          Discuss with your neighbors
        </h2>
        <h2 className="mt-2 text-lg font-semibold text-coolorsPurple2 sm:text-2xl">
          Like, comment, and share
        </h2>
        <div className="relative inline-block scale-75 sm:my-48 sm:scale-[120%] ">
          {/* //* Below is a github Copilot suggestion for making the iphone image lay exactly on top of the video player or image underneath it. Do not touch this css code */}
          <div className="absolute left-1/2 top-1/2 z-[-1] h-full w-full -translate-x-1/2 -translate-y-1/2 transform bg-transparent px-2.5">
            <div className="h-[99%] overflow-hidden rounded-[60px]">
              <img src={Comments} alt="Comments" />
            </div>
          </div>
          <img src={IPhone14Pro} alt="IPhone14Pro" className=" block w-full" />
        </div>
      </div>
      <div ref={ref4} className={`${translateYAnimation4} mx-5 text-center`}>
        <h2
          style={{ lineHeight: "1.25" }}
          className={`${gradientAnimation} text-4xl sm:text-6xl`}
        >
          Save your favorites
        </h2>
        <p className="mt-2 text-lg font-semibold text-coolorsPurple2 sm:text-2xl">
          Bookmark posts to save them to your private collection
        </p>
        <img
          className="scale-75 rounded-3xl sm:my-[-38%] sm:scale-[40%] sm:rounded-[60px]"
          src={Bookmarks}
          alt="Bookmarks"
        />
      </div>
      <div className="mt-6 flex justify-center pb-12">
        <Link
          to="hero"
          smooth={true}
          className="cursor-pointer rounded-full bg-gradient-to-br from-primary via-primary-light to-primary-extraLight p-3 px-9 text-xl font-bold text-white shadow-md"
        >
          Register for the Beta
        </Link>
      </div>
    </section>
  );
};
