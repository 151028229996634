import { useInView } from "react-intersection-observer";
import { BetaEmailForm } from "../../../forms/BetaEmailForm/BetaEmailForm";
import "../../../animations/BackgroundAnimationStyles.css";
import { GradientAnimation } from "../../../animations/GradientAnimation";
import { getTranslateYAnimation } from "../../../helpers/getTranslateYAnimation";
import VideoPlayer from "../../../VideoPlayer/VideoPlayer";
import IPhone14Pro from "../../../../assets/images/IPhone14ProMock.png";

export const HeroSection = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
    trackVisibility: true,
    delay: 100,
  });
  const translateYAnimation = getTranslateYAnimation(inView);

  return (
    <section
      id="hero"
      className="duration-10 relative animate-gradient-10s overflow-hidden bg-gradient-to-tl from-primary-overlay via-primary to-amber-300 bg-size-200 bg-pos-0 sm:flex sm:flex-row sm:items-center sm:justify-center "
    >
      <GradientAnimation />
      <div
        ref={ref}
        className="relative flex flex-col px-4 pt-24 text-center font-bold text-white sm:ml-24 sm:flex-[1] sm:pb-12 sm:pb-12 sm:pl-48 sm:text-left "
      >
        <h2
          className={`${translateYAnimation} text-lg delay-[200ms] sm:hidden sm:pl-5 sm:text-2xl`}
        >
          Los Angeles & Orange County
        </h2>
        <h1
          className={`${translateYAnimation} my-4 text-5xl delay-[300ms] sm:px-5 sm:text-8xl`}
        >
          Find better food faster
        </h1>
        <h2
          className={`${translateYAnimation} text-lg font-semibold delay-[400ms] sm:pl-5 sm:text-3xl`}
        >
          Discover tastier bites, dine quicker,
        </h2>
        <h2
          className={`${translateYAnimation} text-lg font-semibold delay-[500ms] sm:pl-5 sm:text-3xl`}
        >
          and savor the best
        </h2>

        <p
          className={`${translateYAnimation} my-4 text-xl delay-[600ms] sm:my-8 sm:pl-5 sm:text-xl`}
        >
          <p className="hidden sm:inline ">Los Angeles & Orange County</p>
          <p className="mx-2 hidden sm:inline">|</p>
          FALL 2023
        </p>
        <div className={`${translateYAnimation} delay-[900ms]`}>
          <BetaEmailForm />
        </div>
      </div>
      <div
        className={`flex sm:flex-1 ${translateYAnimation} my-4 ml-12 w-full text-xl delay-[600ms] sm:my-6 sm:mt-[-1%]  sm:text-2xl`}
      >
        <div className="hidden scale-75 sm:mt-24 sm:block sm:scale-[90%]">
          <div className="absolute left-1/2 top-1/2 z-[-1] h-full w-full -translate-x-1/2 -translate-y-1/2 transform overflow-hidden rounded-[20%] bg-transparent p-1.5">
            <div className="h-full w-full overflow-hidden">
              <VideoPlayer />
            </div>
          </div>
          <img src={IPhone14Pro} alt="IPhone14Pro" className=" block w-full" />
        </div>
      </div>
    </section>
  );
};
